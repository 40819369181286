import React from "react";
import MapWrapper from "../components/Map";

function Homepage() {

  return (
    <div>
      <MapWrapper />
    </div>
  )
}

export default Homepage;


